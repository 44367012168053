var timer = {
    warningTimer: '',
    redirectTimer: '',
    init: function()
    {
        timer.setTimer();
        $('body').on('click', '.timer-extend', function()
        {
            clearTimeout(timer.warningTimer);
            clearTimeout(timer.redirectTimer);
            timer.setTimer();
            ccModal.hide();
        });
    },
    setTimer: function()
    {
        timer.warningTimer = setTimeout(function(){
            $('.cc-modal-body').load('/timeout', function()
            {
                $('.cc-modal-title').html('Do you need more time?');
                ccModal.show();
            });
        }, 900000);

        timer.redirectTimer = setTimeout(function(){
            document.location='/cas/login?m=110';
        }, 930000);
    }
};