var password = {
    removeOkClasses: function()
    {
        $('.ok-check').removeClass('ok-complete');
        $('.ok-check').addClass('ok-not-complete');
    },
    checkPasswordMatch: function(password, confirmPassword, inputElement)
    {
        var passwordsMatch = false;
        ele = $('.ok-check-match');
        if (password == confirmPassword)
        {
            inputElement.popover('hide')
            passwordsMatch = true;
        }
        else
        {
            if (!inputElement.next('div.popover:visible').length)
            {
                inputElement.popover('show')
            }
        }
        return passwordsMatch;
    },

    checkPasswordRules: function(passwordElement)
    {
        var rulesMet = true;
        password.removeOkClasses();

        var enoughRegex = new RegExp("(?=.{8,}).*", "g");

        var upper = new RegExp("[A-Z]+");
        var lower = new RegExp("[a-z]+");
        var number = new RegExp("[0-9]+");
        var symbol = new RegExp("\\W+");

        var pwd = passwordElement.val();

        if (pwd.length > 0)
        {
            if (enoughRegex.test(pwd))
            {
                var ele = $('.ok-check-min');
                ele.removeClass('ok-not-complete');
                ele.addClass('ok-complete');
            }
            else
            {
                rulesMet = false;
            }

            if (upper.test(pwd) && lower.test(pwd))
            {
                var ele = $('.ok-check-letter');
                ele.removeClass('ok-not-complete');
                ele.addClass('ok-complete');
            }
            else
            {
                rulesMet = false;
            }

            if (number.test(pwd) || symbol.test(pwd))
            {
                var ele = $('.ok-check-num');
                ele.removeClass('ok-not-complete');
                ele.addClass('ok-complete');
            }
            else
            {
                rulesMet = false;
            }

            var banned = $('.password-restricted-words').html().split('|');
            $('.mun-login-in-password').addClass('hidden');
            banned.forEach(function(ban)
            {
                var banRegEx = new RegExp(ban, "gi");
                if(banRegEx.test(pwd))
                {
                    $('.mun-login-in-password').removeClass('hidden');
                    rulesMet = false;
                }
            });

            $('.space-in-password').addClass('hidden');
            var spaceRegEx = new RegExp(" ", "gi");
            if (spaceRegEx.test(pwd))
            {
                $('.space-in-password').removeClass('hidden');
                rulesMet = false;
            }

            banned.forEach(function(ban)
            {
                var banRegEx = new RegExp(ban, "gi");
                if(banRegEx.test(pwd))
                {
                    $('.mun-login-in-password').removeClass('hidden');
                    rulesMet = false;
                }
            });
        }
        else
        {
            rulesMet = false;
        }

        return rulesMet;
    }
};
