var legacyForgotPassword = {
    confirmSmsInit: function()
    {
        $('#form-sms-code').focus();
        ccModal.init();
    },
    requestNewSms: function()
    {
        $('.cc-modal-body').load('/forgot-password/legacy/request-new-sms', function()
        {
            ccModal.show();
            $('#cc-modal').on('shown.bs.modal', function()
            {
                $('.request-new-sms-submit').unbind().on('click', function()
                {
                    $.ajax({
                        type: "POST",
                        url: '/api/forgot-password/legacy/request-new-sms',
                        data: {
                            "cellphone" : $('#request-new-sms-cellphone').val(),
                            "_token" : $('input[name="_token"]').attr('value')
                        },
                        success: function(json)
                        {
                            ccModal.hide();
                        },
                        error: function(xhr)
                        {
                            ccModal.alert('alert-danger', xhr.responseJSON.error.message);
                        }
                    });
                });

            });
        });
    },
    handleBadData: function(json)
    {
        if (errors.exists(json.errors, 'memorial_number'))
        {
            $('.legacy-warning-no-match').removeClass('hidden');
            $('.legacy-memorial-number').focus();
            $('html, body').animate({
                scrollTop: $(".legacy-warning-no-match").offset().top
            }, 1000);
        }
    },
    missingData: function(json)
    {
        $('.legacy-warning-missing').removeClass('hidden');
        $('html, body').animate({
            scrollTop: $(".legacy-warning-missing").offset().top
        }, 1000);
    }
};