var setPasswordForm = {
    init: function()
    {
        $('#set-password-form-password').popover({
            html : true,
            content: function() {
                return $('#password-popover').html();
            }
        });
        $('#set-password-form-password-confirm').popover({
            html : true,
            content: function() {
                return $('#password-confirm-popover').html();
            }
        });

        $('#mun-login-id-reminder').on('hidden.bs.modal', function(e)
        {
            console.log('close');
            $('#set-password-form-password').focus();
        }).modal('toggle');

        $('#set-password-form-password').on('keyup', function()
        {
            password.checkPasswordRules($(this));
        });

        $('#set-password-form-password-confirm').on('keyup', function()
        {
            password.checkPasswordMatch($(this).val(), $('#set-password-form-password').val(), $(this));
        });

        $('#set-password-form-password').on('blur', function()
        {
            if (!password.checkPasswordRules($(this)))
            {
                $('#set-password-form-password').focus();
                $('#set-password-form-password').popover('show');
            }
        });

    }
};