var passwordResetOptions = {
    requestNewSms: function()
    {
        $('.cc-modal-body').load('/password-reset-options/set-cell-phone/request-new-sms', function()
        {
            ccModal.show();
            $('#cc-modal').on('shown.bs.modal', function()
            {
                $('.request-new-sms-submit').unbind().on('click', function()
                {
                    $.ajax({
                        type: "POST",
                        url: '/api/password-reset-options/set/request-new-sms',
                        data: {
                            "cellphone": $('#request-new-sms-cellphone').val(),
                            "_token" : $('input[name="_token"]').attr('value')
                        },
                        success: function(json)
                        {
                            ccModal.hide();
                        },
                        error: function(xhr)
                        {
                            ccModal.alert('alert-danger', 'A new SMS could not be sent.  Please try again.');
                        }
                    });
                });

            });
        });
    },
    requestNewSmsUpdate: function()
    {
        $('.cc-modal-body').load('/password-reset-options/update-cell-phone/request-new-sms', function()
        {
            ccModal.show();
            $('#cc-modal').on('shown.bs.modal', function()
            {
                $('.request-new-sms-submit').unbind().on('click', function()
                {
                    $.ajax({
                        type: "POST",
                        url: '/api/password-reset-options/update/request-new-sms',
                        data: {
                            "cellphone": $('#request-new-sms-cellphone').val(),
                            "_token" : $('input[name="_token"]').attr('value')
                        },
                        success: function(json)
                        {
                            ccModal.hide();
                        },
                        error: function(xhr)
                        {
                            ccModal.alert('alert-danger', 'A new SMS could not be sent.  Please try again.');
                        }
                    });
                });

            });
        });
    },
    updateInit: function()
    {
        $('body').on('click', '.password-check-button', function(e){
            e.preventDefault();
            buttonActions.loading($(this));
            console.log('clicked');
            $('.password-alert').addClass('hidden');
            $.ajax({
                type: "POST",
                url: '/api/password-reset-options/authen',
                data: {
                    "password" : $('#password').val(),
                    "_token" : $('input[name="_token"]').attr('value')
                },
                error: function(xhr)
                {
                    console.log(xhr.responseJSON);
                    $('.password-alert').html(xhr.responseJSON.error.message);
                    $('.password-alert').removeClass('hidden');
                    buttonActions.doneLoading($('.password-check-button'));
                },
                success: function()
                {
                    $('.password-form').submit();
                }
            });
        });

    }
};
