var forgotUsername = {
    init: function()
    {
        $('body').on('click', '.form-submit', function(e)
        {
            buttonActions.loading($(this));
            $('.form-warning').addClass('hidden');
            e.preventDefault();
            $.ajax({
                type: "POST",
                url: '/api/forgot-username/check-data',
                data: {
                    "memorial_number": $('#memorial-number').val(),
                    "dob": $('#dob-year').val() + $('#dob-month').val() + $('#dob-day').val(),
                    "_token" : $('input[name="_token"]').attr('value')
                },
                success: function(json)
                {
                    $('#forgot-username-form').submit();
                },
                error: function(xhr)
                {
                    forgotUsername.handleBadData(xhr);
                }
            });
        });
        $(function () {
            $('[data-toggle="popover"]').popover()
        })

        $('#memorial-number').focus();
    },
    handleBadData: function(xhr)
    {
        buttonActions.doneLoading($('.form-submit'));
        console.log(xhr);
        $('.form-warning-message').html(xhr.responseJSON.error.message);
        $('#memorial-number-warning').removeClass('hidden');
        switch (xhr.status)
        {
            case 403:
                break;
        }

    }
};