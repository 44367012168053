var loginForm = {
	init: function()
	{

	},
	checkCredentials: function()
	{

	}
};


var confirmSMS = {
	getNewSMS: function()
	{
		$('.cc-modal-body').load('/setup/request-new-sms', function()
		{
			ccModal.show();
			$('#cc-modal').on('shown.bs.modal', function()
			{

			});
		});
	}
};

var selfService = {
	init: function()
	{

	}
};




var newSms = {
	requestNewSms: function()
	{
		$('.cc-modal-body').load('/create-account/request-new-sms', function()
		{
			ccModal.show();
			$('#cc-modal').on('shown.bs.modal', function()
			{
				$('.request-new-sms-submit').unbind().on('click', function()
				{
					$.ajax({
						type: "POST",
						url: '/api/setup/request-new-sms',
						data: {
							"cellphone": $('#request-new-sms-cellphone').val(),
							"_token" : $('input[name="_token"]').attr('value')
						},
						success: function(json)
						{
							console.log('DATA GOOD');
							ccModal.hide();
						},
						error: function(xhr)
						{
							console.log(xhr.responseJSON);
							ccModal.hide();

						}
					});
				});

			});
		});
	}
};


var errors = {
	exists: function(errors, type)
	{
		for (i=0; i<errors.length; i++)
		{
			if (errors[i].type == type)
			{
				return true;
			}
		};
		return false;
	}
};


var ccModal = {
	init: function (){
		var $ = jQuery;
		ccModal.hideAlert();
		$(document).on('click', '.cc-modal', function (e) {
			e.preventDefault();

			if (typeof  $(this).data('cc-modal-title'))
			{
				$('.cc-modal-title').html($(this).data('cc-modal-title'));

			}
			var dataCallbackFunction, dataParameters, functionStack, callbackFunction, callbackParams, i;

			dataCallbackFunction = $(this).data('cc-modal-function');
			dataParameters = $(this).data('cc-modal-params');
			if (typeof dataCallbackFunction !== 'undefined') {
				functionStack = dataCallbackFunction.split('.');
				callbackFunction = window;

				for (i = 0; i < functionStack.length; i++) {
					callbackFunction = callbackFunction[functionStack[i]];
				}

				callbackParams = [];
				if (typeof dataParameters !== 'undefined') {
					callbackParams = String(dataParameters).split('||');
				}

				if (typeof callbackFunction == 'function') {
					callbackFunction.apply(window[functionStack[0]], callbackParams);
				}
			}
		});
		$('.cc-modal-alert-close').click(function()
		{
			$('.cc-modal-body').html('');
			ccModal.hideAlert();
		});

	},
	setSize: function(size)
	{
		$('#cc-modal-dialog').removeClass('modal-lg');
		if (size == 'modal-lg')
		{
			console.log('set modal to lg');
			$('#cc-modal-dialog').addClass('modal-lg');
		}
	},
	show:function()
	{
		ccModal.hideAlert();
		var replaceButtons = $('.footer-buttons').html();
		if (replaceButtons != '')
		{
			$('.cc-modal-footer').html(replaceButtons);
			$('#cc-modal').modal('show');
		}
	},
	hide:function()
	{
		$('.cc-modal-body').html('');
		$('#cc-modal').modal('hide');
		$('#cc-modal-dialog').size(false);
	},
	alert:function(alertClass,message)
	{
		var a = $('#cc-modal-alert');
		a.removeClass();
		a.addClass('alert ' + alertClass);
		$('.modal-alert-body').html(message);
		a.css('display', 'block');
	},
	hideAlert:function()
	{
		$('#cc-modal-alert').hide();
	},
	load: function(url)
	{
		$('.cc-modal-body').load(url);
	}
};

