var forgotPassword = {
    init: function()
    {
        $('.forgot-password-form-submit').unbind().on('click', function()
        {
            buttonActions.loading($(this));
            $('#forgot-password-form').submit();
        });
        $('#forgot-password-form-uid').focus();
    },
    initPassword: function()
    {
        $('.forgot-password-set-password-form-submit').unbind().on('click', function(e)
        {
            e.preventDefault();
            if ($('#forgot-password-form-new-password-confirm').val() != $('#forgot-password-form-new-password').val())
            {
                setTimeout(function(){ $('#forgot-password-form-new-password-confirm').focus(); }, 250);
            }
            else
            {
                $('#forgot-password-set-password-form').submit();
            }
        });


        pwField = $('#forgot-password-form-new-password');
        pwField.popover({
            html : true,
            content: function() {
                return $('#password-popover').html();
            }
        });
        pwField.on('keyup', function()
        {
            password.checkPasswordRules($(this));
        });
        pwField.focus();

        pwField.on('blur', function()
        {
            if (!password.checkPasswordRules($(this)))
            {
                pwField.focus();
                pwField.popover('show');
            }
        });

        $('#forgot-password-form-new-password-confirm').on('keyup', function()
        {
            password.checkPasswordMatch($(this).val(), $('#forgot-password-form-new-password').val(), $(this));
        });

        $('#forgot-password-form-new-password-confirm').popover({
            html : true,
            content: function() {
                return $('#password-confirm-popover').html();
            }
        });
    },
    requestNewSms: function()
    {
        $('.cc-modal-body').load('/forgot-password/request-new-sms', function()
        {
            ccModal.show();
            $('#cc-modal').on('shown.bs.modal', function()
            {
                $('.request-new-sms-submit').unbind().on('click', function()
                {
                    $.ajax({
                        type: "POST",
                        url: '/api/forgot-password/request-new-sms',
                        data: {
                            "_token" : $('input[name="_token"]').attr('value')
                        },
                        success: function(json)
                        {
                            ccModal.hide();
                        },
                        error: function(xhr)
                        {
                            ccModal.alert('Could not send new SMS');
                        }
                    });
                });

            });
        });
    }
};