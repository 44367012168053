var buttonActions = {
    loading:function(btn)
    {
        var orig = btn.html();
        btn.prop('disabled', true);
        btn.html('<span class="button-loading"></span><span class="hidden button-original-text">' + orig + '</span>');
    },
    doneLoading: function(btn)
    {
        btn.prop('disabled', false);
        btn.html(btn.find('.button-original-text').first().html());
    }
};
