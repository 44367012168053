var legacyForgotPasswordEmployee = {
    init: function () {
        ccModal.init();
        $('.forgot-password-legacy-submit').unbind().on('click', function (e) {
            buttonActions.loading($(this));
            e.preventDefault();
            $('.form-warning').addClass('hidden');
            $('.banner-error-messages').addClass('hidden');
            $.ajax({
                type: "POST",
                url: '/api/forgot-password/check-employee-form-data',
                data: {
                    "employee_number": $('#legacy-employee-number').val(),
                    "sin": $('#legacy-employee-sin').val(),
                    "dob": $('#legacy-employee-dob-year').val() + $('#legacy-employee-dob-month').val() + $('#legacy-employee-dob-day').val(),
                    "cellphone": $('.collect-self-service-cellphone').val(),
                    "external_email": $('.collect-self-service-email').val(),
                    "_token": $('input[name="_token"]').attr('value')
                },
                success: function (json, status, xhr) {
                    if (xhr.status == 280)
                    {
                        legacyForgotPasswordEmployee.lazarusFound();
                    }
                    else
                    {
                        $('#legacy-employee-form').submit();
                    }
                },
                error: function (xhr) {
                    buttonActions.doneLoading($('.forgot-password-legacy-submit'));
                    $('.legacy-warning').addClass('hidden');
                    switch (xhr.status) {
                        case 506:
                            $('#banner-error-message-506').removeClass('hidden');
                            break;
                        case 420:
                            legacyForgotPassword.handleBadData(xhr.responseJSON);
                            break;
                        case 421:
                            selfServiceOptions.handleBadData(xhr.responseJSON);
                            break;
                        case 422:
                            legacyForgotPassword.missingData(xhr.responseJSON);
                            break;
                        case 423:
                            selfServiceOptions.handleBadData(xhr.responseJSON);
                            break;
                        default:
                            selfServiceOptions.handleBadData(xhr.responseJSON);

                    }
                }
            });
        });

        $('body').on('click', '.sin-viewer', function () {
            if ($('#legacy-employee-sin').attr('type') == 'password') {
                $(this).removeClass('glyphicon-eye-open');
                $(this).addClass('glyphicon-eye-close');
                $('#legacy-employee-sin').attr('type', 'text');
            }
            else {
                $(this).removeClass('glyphicon-eye-close');
                $(this).addClass('glyphicon-eye-open');
                $('#legacy-employee-sin').attr('type', 'password');
            }

        });

        $('#legacy-employee-number').on('blur', function (e) {
            //legacyForgotPasswordEmployee.checkLazarus();
            legacyForgotPasswordEmployee.checkEmployeeBannerData();
        });
        $('#legacy-employee-sin').on('blur', function (e) {
            //legacyForgotPasswordEmployee.checkLazarus();
            legacyForgotPasswordEmployee.checkEmployeeBannerData();
        });
    },
    checkEmployeeBannerData: function()
    {
        if ($('#legacy-employee-number').val() != '' && $('#legacy-employee-sin').val() != '')
        {
            $('.banner-error-messages').addClass('hidden');
            $('.form-warning').addClass('hidden');
            $.ajax({
                type: "POST",
                url: '/api/forgot-password/check-banner-employee-data',
                data: {
                    "employee_number": $('#legacy-employee-number').val(),
                    "sin": $('#legacy-employee-sin').val(),
                    "dob": $('#legacy-employee-dob-year').val() + $('#legacy-employee-dob-month').val() + $('#legacy-employee-dob-day').val(),
                    "_token" : $('input[name="_token"]').attr('value')
                },
                success: function(json, status, xhr)
                {
                    if (xhr.status  == 280)
                    {
                        legacyForgotPasswordEmployee.lazarusFound();
                    }
                },
                error: function(xhr)
                {
                    switch (xhr.status){
                        case 506:
                            $('#banner-error-message-' + xhr.status).removeClass('hidden');
                            break;
                        default:
                            $('#banner-error-message-generic').removeClass('hidden');
                    }

                    $('html, body').animate({
                        scrollTop: $("#banner-error-messages-container").offset().top
                    }, 1000);
                }
            });
        }
    },
    checkLazarus: function ()
    {
        if ($('#legacy-employee-number').val() != '' && $('#legacy-employee-sin').val() != '')
        {
            $.ajax({
                type: "POST",
                url: '/api/forgot-password/check-lazarus-employee',
                data: {
                    "employee_number": $('#legacy-employee-number').val(),
                    "sin": $('#legacy-employee-sin').val(),
                    "dob": $('#legacy-employee-dob-year').val() + $('#legacy-employee-dob-month').val() + $('#legacy-employee-dob-day').val(),
                    "_token" : $('input[name="_token"]').attr('value')
                },
                success: function(json)
                {
                    legacyForgotPasswordEmployee.lazarusFound();
                },
                error: function(xhr)
                {

                }
            });
        }

    },
    lazarusFound: function()
    {
        $('.legacy-employee-content-container').html($('.lazarus-warning').html());
    }

};