var selfServiceOptions = {

    init: function()
    {
        // When this function is called, it should bind focus/blur actions to matching form elements

        var cellElement = $('.collect-self-service-cellphone');
        var cellElementHelp = $('.collect-self-service-cellphone-help-text');

        cellElement.on('focus', function()
        {
            cellElementHelp.removeClass('hidden');
        });
        cellElement.on('blur', function()
        {
            setTimeout(function(){cellElementHelp.addClass('hidden');}, 250);
        });

        var emailElement = $('.collect-self-service-email');
        var emailElementHelp = $('.collect-self-service-email-help-text');

        emailElement.on('focus', function()
        {
            emailElementHelp.removeClass('hidden');
        });
        emailElement.on('blur', function()
        {
            setTimeout(function(){emailElementHelp.addClass('hidden');}, 250);
        });
    },
    handleBadData: function(json)
    {
        $('.collect-self-service-warning').addClass('hidden');

        if (selfServiceOptions.errorExists(json.errors, 'externals_blank'))
        {
            $('.collect-self-service-cellphone-warning').removeClass('hidden');
            $('html, body').animate({
                scrollTop: $(".collect-self-service-cellphone-warning").offset().top
            }, 1000);
            $('.collect-self-service-cellphone').focus();
        }
        else
        {
            if ($('.collect-self-service-cellphone').val() != ''&& selfServiceOptions.errorExists(json.errors, 'bad_cellphone'))
            {
                $('.collect-self-service-cellphone-format-warning').removeClass('hidden');
                $('html, body').animate({
                    scrollTop: $(".collect-self-service-cellphone-format-warning").offset().top
                }, 1000);
                $('.collect-self-service-cellphone').focus();
            }
            else if(selfServiceOptions.errorExists(json.errors, 'bad_external_email'))
            {
                $('.collect-self-service-email-format-warning').removeClass('hidden');
                $('html, body').animate({
                    scrollTop: $(".collect-self-service-email-format-warning").offset().top
                }, 1000);
                $('.collect-self-service-email').focus();

            }
            else
            {
                console.log('Generic warning');
                console.log(json.error);
                $('.legacy-warning-generic').html(json.error.message);
                $('.legacy-warning-generic').removeClass('hidden');

            }


        }
    },
    errorExists: function(errors, type)
    {
        if (errors)
        {
            for (i=0; i<errors.length; i++)
            {
                if (errors[i].type == type)
                {
                    return true;
                }
            };
        }

        return false;
    }
};