var feedback = {
    showForm: function(url)
    {
        $('.cc-modal-body').load('/feedback', function()
        {
            ccModal.show();
            $('#cc-modal').on('shown.bs.modal', function()
            {
                $('#email').focus();
                $('.feedback-submit').unbind().on('click', function()
                {
                    $.ajax({
                        type: "POST",
                        url: '/api/feedback',
                        data: {
                            "comment": $('#comment').val(),
                            "email": $('#email').val(),
                            "url": url,
                            "_token" : $('input[name="_token"]').attr('value')
                        },
                        success: function(json)
                        {
                            console.log(json);
                            ccModal.hide();
                        },
                        error: function(xhr)
                        {
                            ccModal.alert('alert-danger', xhr.responseJSON.error.message);
                            console.log(xhr.responseJSON);
                        }
                    });

                });

            });
        });
    }
};