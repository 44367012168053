var changePassword = {
    init: function()
    {
        $('#change-password-current-password').focus();

        $('#change-password-form-new-password').on('keypress', function(){
            changePassword.initPassword();
        });


        $('#change-password-current-password').on('keyup', function(){
            $('.current-password-alert').addClass('hidden');
        });

        $('.change-password-form-submit').unbind().on('click', function(e)
        {
            e.preventDefault();
            if ($('#change-password-form-new-password').val() != $('#change-password-form-new-password-confirm').val())
            {
                setTimeout(function(){ $('#change-password-form-new-password-confirm').focus(); }, 250);
            }
            else
            {
                changePassword.checkPassword($('.change-password-uid').data('uid'), $('#change-password-current-password').val());
            }

        });
    },
    initWithExternals: function()
    {
        changePassword.init();
        $('.change-password-form-submit').unbind().on('click', function(e)
        {
            $('.collect-self-service-warning').addClass('hidden');
            e.preventDefault();
            if ($('#change-password-form-new-password').val() != $('#change-password-form-new-password-confirm').val())
            {
                setTimeout(function(){ $('#change-password-form-new-password-confirm').focus(); }, 250);
            }
            else
            {
                if ($('.collect-self-service-cellphone').val() == '' && $('.collect-self-service-email').val() == '')
                {
                    $('.collect-self-service-cellphone-warning').removeClass('hidden');
                }
                else
                {
                    $('#change-password-form').submit();
                }
            }

        });
        $('.collect-self-service-cellphone').on('blur', function()
        {
            changePassword.checkNumber($(this).val());
        })

    },
    checkPassword: function(uid, password)
    {
        console.log($('input[name="_token"]').attr('value'));
        $.ajax({
            type: "POST",
            url: '/api/change-password/authen',
            data: {
                "uid": uid,
                "password": password,
                "_token" : $('input[name="_token"]').attr('value')
            },
            success: function(json)
            {
                if (json.responseCode == 280)
                {
                    changePassword.lazarusFound();
                }
                else
                {
                    $('#change-password-form').submit();
                }

            },
            error: function(xhr)
            {
                $('.current-password-alert').removeClass('hidden');
                setTimeout(function(){ $('#change-password-current-password').focus(); }, 250);
            }
        });
    },
    initPassword: function()
    {
        pwField = $('#change-password-form-new-password');
        pwField.popover({
            html : true,
            content: function() {
                return $('#password-popover').html();
            }
        });
        pwField.on('keyup', function()
        {
            password.checkPasswordRules($(this));
        });
        pwField.focus();

        pwField.on('blur', function()
        {
            if (!password.checkPasswordRules($(this)))
            {
                pwField.focus();
                pwField.popover('show');
            }
        });

        $('#change-password-form-new-password-confirm').on('keyup', function()
        {
            password.checkPasswordMatch($(this).val(), $('#change-password-form-new-password').val(), $(this));
        });

        $('#change-password-form-new-password-confirm').popover({
            html : true,
            content: function() {
                return $('#password-confirm-popover').html();
            }
        });
    },
    checkNumber: function(cell)
    {
        $('.collect-self-service-cellphone-format-warning').addClass('hidden');
        if (cell.length > 0)
        {
            console.log(cell);
            $.ajax({
                type: "POST",
                url: '/api/check-cell-number',
                data: {
                    "cell": cell,
                    "_token" : $('input[name="_token"]').attr('value')
                },
                success: function(json)
                {
                    console.log(json);
                },
                error: function(xhr)
                {
                    console.log(xhr.status);
                    $('.collect-self-service-cellphone-format-warning').removeClass('hidden');
                    console.log(xhr);
                    //$('.current-password-alert').removeClass('hidden');
                    //setTimeout(function(){ $('#change-password-current-password').focus(); }, 250);
                }
            });
        }
    },
    requestNewSms: function()
    {
        $('.cc-modal-body').load('/change-password/set-password-reset-options/request-new-sms', function()
        {
            ccModal.show();
            $('#cc-modal').on('shown.bs.modal', function()
            {
                $('.request-new-sms-submit').unbind().on('click', function()
                {
                    $.ajax({
                        type: "POST",
                        url: '/api/change-password/request-new-sms',
                        data: {
                            "cellphone": $('#request-new-sms-cellphone').val(),
                            "_token" : $('input[name="_token"]').attr('value')
                        },
                        success: function(json)
                        {
                            console.log('DATA GOOD');
                            ccModal.hide();
                        },
                        error: function(xhr)
                        {
                            console.log(xhr.responseJSON);
                        }
                    });
                });

            });
        });
    },
    passwordResetOptionsInit: function()
    {
        $('.current-password').focus();
        $('body').on('blur', '.current-password', function()
        {
            if ($('.current-password').val() != '')
            {
                $('.current-password-warning').addClass('hidden');
                $.ajax({
                    type: "POST",
                    url: '/api/change-password/authen',
                    data: {
                        "uid": $('.uid').val(),
                        "password": $('.current-password').val(),
                        "_token" : $('input[name="_token"]').attr('value')
                    },
                    success: function(json)
                    {

                    },
                    error: function(xhr)
                    {
                        $('.current-password-warning-text').html('The password you provided is incorrect');
                        $('.current-password-warning').removeClass('hidden');
                        //$('.current-password').focus();
                        changePassword.passwordResetOptionsHandleError(xhr);
                    }
                });
            }
        });
        $('body').on('click', '.password-options-submit', function()
        {
            $('.warning-block-options').addClass('hidden');
            buttonActions.loading($(this));
            $.ajax({
                type: "POST",
                url: '/api/change-password/check-password-reset-options-data',
                data: {
                    "cellphone": $('.cellphone').val(),
                    "external_email": $('.external-email').val(),
                    "_token" : $('input[name="_token"]').attr('value')
                },
                success: function(json)
                {
                    $('#set-password-reset-options-form').submit();
                },
                error: function(xhr)
                {
                    console.log('got a bad response');
                    changePassword.passwordResetOptionsHandleError(xhr);
                }
            });
        });
    },
    passwordResetOptionsHandleError: function(xhr)
    {
        var text = $('.warning-block-reset-options-text');
        var container = $('.warning-block-reset-options');
        console.log(xhr);
        if (xhr.responseJSON.errors.general)
        {
            text.html(xhr.responseJSON.errors.general);
            container.removeClass('hidden');
            console.log('general error');
        }
        else if (xhr.responseJSON.errors.cellphone)
        {
            text.html(xhr.responseJSON.errors.cellphone);
            container.removeClass('hidden');
        }
        else if (xhr.responseJSON.errors.external_email)
        {
            console.log('ere');
            text.html(xhr.responseJSON.errors.external_email);
            container.removeClass('hidden');
        }
        buttonActions.doneLoading($('.password-options-submit'));
    },
    lazarusFound: function()
    {
        $('.change-password-content-container').html($('.lazarus-warning').html());
    }
};
