var legacyForgotPasswordStudent = {
    init: function () {
        ccModal.init();
        $('.forgot-password-legacy-submit').unbind().on('click', function (e) {
            e.preventDefault();
            buttonActions.loading($(this));
            $('.form-warning').addClass('hidden');
            $('.banner-error-messages').addClass('hidden');

            $.ajax({
                type: "POST",
                url: '/api/forgot-password/check-student-form-data',
                data: {
                    "student_number": $('#legacy-student-number').val(),
                    "pin": $('#legacy-pin').val(),
                    "cellphone": $('.collect-self-service-cellphone').val(),
                    "external_email": $('.collect-self-service-email').val(),
                    "_token": $('input[name="_token"]').attr('value')
                },
                success: function (json, status, xhr) {
                    if (xhr.status == 280)
                    {
                        legacyForgotPasswordStudent.lazarusFound();
                    }
                    else
                    {
                        $('#legacy-student-form').submit();
                    }

                },
                error: function (xhr) {
                    buttonActions.doneLoading($('.forgot-password-legacy-submit'));
                    $('.legacy-warning').addClass('hidden');
                    switch (xhr.status) {
                        case 506:
                            $('#banner-error-message-506').removeClass('hidden');
                            break;
                        case 420:
                            legacyForgotPassword.handleBadData(xhr.responseJSON);
                            break;
                        case 421:
                            selfServiceOptions.handleBadData(xhr.responseJSON);
                            break;
                        case 422:
                            legacyForgotPassword.missingData(xhr.responseJSON);
                            break;
                        case 423:
                            selfServiceOptions.handleBadData(xhr.responseJSON);
                            break;

                    }
                }
            });
        });

        $('#legacy-student-number').on('blur', function (e) {
            //legacyForgotPasswordStudent.checkLazarus();
            legacyForgotPasswordStudent.checkStudentBannerData();
        });
        $('#legacy-pin').on('blur', function (e) {
            //legacyForgotPasswordStudent.checkLazarus();
            legacyForgotPasswordStudent.checkStudentBannerData();
        });
    },
    checkStudentBannerData: function()
    {
        if ($('#legacy-student-number').val() != '' && $('#legacy-pin').val() != '')
        {
            $('.banner-error-messages').addClass('hidden');
            $('.form-warning').addClass('hidden');
            $.ajax({
                type: "POST",
                url: '/api/forgot-password/check-banner-student-data',
                data: {
                    "student_number": $('#legacy-student-number').val(),
                    "pin": $('#legacy-pin').val(),
                    "_token" : $('input[name="_token"]').attr('value')
                },
                success: function(json, status, xhr)
                {
                    if (xhr.status  == 280)
                    {
                        legacyForgotPasswordStudent.lazarusFound();
                    }
                },
                error: function(xhr)
                {
                    switch (xhr.status){
                        case 506:
                            $('#banner-error-message-' + xhr.status).removeClass('hidden');
                            break;
                        default:
                            $('#banner-error-message-generic').removeClass('hidden');
                    }

                    $('html, body').animate({
                        scrollTop: $("#banner-error-messages-container").offset().top
                    }, 1000);
                }
            });
        }
    },
    checkLazarus: function ()
    {
        if ($('#legacy-student-number').val() != '' && $('#legacy-pin').val() != '')
        {
            $.ajax({
                type: "POST",
                url: '/api/forgot-password/check-lazarus-student',
                data: {
                    "student_number": $('#legacy-student-number').val(),
                    "pin": $('#legacy-pin').val(),
                    "_token" : $('input[name="_token"]').attr('value')
                },
                success: function(json)
                {
                    legacyForgotPasswordStudent.lazarusFound();
                },
                error: function(xhr)
                {

                }
            });
        }

    },
    lazarusFound: function()
    {
        $('.legacy-student-content-container').html($('.lazarus-warning').html());
    }


};