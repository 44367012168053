var setupForm = {
    init: function()
    {
        $('body').on('click', '.setup-form-submit', function(e)
        {
            buttonActions.loading($(this));
            e.preventDefault();
            $.ajax({
                type: "POST",
                url: '/api/setup/check-form-data',
                data: {
                    "memorial_number": $('#create-account-form-memorial-number').val(),
                    //"dob": $('#create-account-form-dob-day').val() + '-' + $('#create-account-form-dob-month').val() + '-' + $('#create-account-form-dob-year').val(),
                    "dob": $('#create-account-form-dob-year').val() + $('#create-account-form-dob-month').val() + $('#create-account-form-dob-day').val(),
                    "cellphone" : $('#create-account-form-cellphone').val(),
                    "external_email" : $('#create-account-form-external-email').val(),
                    "_token" : $('input[name="_token"]').attr('value')
                },
                success: function(json)
                {
                    console.log('DATA GOOD');
                    $('#create-account-form').submit();
                },
                error: function(xhr)
                {
                    buttonActions.doneLoading($('.setup-form-submit'));
                    $('.create-account-form-warning').addClass('hidden');
                    console.log(xhr.responseJSON);
                    switch(xhr.status)
                    {
                        case 410:
                            setupForm.handleBadData(xhr.responseJSON);
                            break;
                        case 422:
                            console.log(422);
                            var warning = $('#create-account-form-general-warning');
                            warning.html('Please ensure you provide a Memorial Number and date of birth');
                            warning.removeClass('hidden');
                            break;

                    }
                }
            });
        });
    },
    validateMemorialNumber: function(number)
    {
        var re = /^[0-9]{7,9}$/;
        return re.exec(number);
    },
    validateMemorialNumberAndDOB: function()
    {


    },
    handleBadData: function(json)
    {
        if (setupForm.errorExists(json.errors, 'lazarus'))
        {
            setupForm.lazarusFound();
        }
        else if (setupForm.errorExists(json.errors, 'memorial_number'))
        {
            $('#create-account-form-memorial-number-warning').removeClass('hidden');
            $('html, body').animate({
                scrollTop: $("#create-account-form-memorial-number-warning").offset().top
            }, 1000);
            setTimeout($('#create-account-form-memorial-number').focus(), 255);
        }
        else if (setupForm.errorExists(json.errors, 'already_set_up'))
        {
            $('#create-account-form-memorial-number-warning-exists').removeClass('hidden');
            $('html, body').animate({
                scrollTop: $("#create-account-form-memorial-number-warning-exists").offset().top
            }, 1000);
            setTimeout($('#create-account-form-memorial-number').focus(), 255);
        }
        else
        {
            if (setupForm.errorExists(json.errors, 'externals_blank'))
            {
                $('#create-account-form-cellphone-warning').removeClass('hidden');
                $('html, body').animate({
                    scrollTop: $("#create-account-form-cellphone-format-warning").offset().top
                }, 1000);
                $('#create-account-form-cellphone').focus();
            }
            else
            {
                if ($('#create-account-form-cellphone').val() != ''&& setupForm.errorExists(json.errors, 'bad_cellphone'))
                {
                    $('#create-account-form-cellphone-format-warning').removeClass('hidden');
                    $('html, body').animate({
                        scrollTop: $("#create-account-form-cellphone-format-warning").offset().top
                    }, 1000);
                    $('#create-account-form-cellphone').focus();
                }
            }
        }
    },
    errorExists: function(errors, type)
    {
        for (i=0; i<errors.length; i++)
        {
            if (errors[i].type == type)
            {
                return true;
            }
        };
        return false;
    },
    lazarusFound: function()
    {
        $('.setup-content-container').html($('.lazarus-warning').html());
    }
};